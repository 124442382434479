import FormComponent from "./form.component.vue";
// import {couponService} from "../../../../services/facility/coupon/coupon.service";
import PaginateComponent from "../../../components/paginate.component.vue";
import {alertService} from "../../../../services/alert/alert.service";
import {userService} from "../../../../services/facility/user/user.service";

export default {
    components: {PaginateComponent,FormComponent},
    data() {
        return {
            data: {
                data: []
            },
            filter: {
                paginate: 1,
                per_page: 10,
                page: 1
            },
            def: {
                paginate: 1,
                per_page: 10,
                page: 1
            }

        }
    },
    async mounted() {
       await this.list(this.filter)

    },
    methods: {

        async list(query) {

            await userService.list(query).then((success) => {
                this.data = success.data;
                console.log(  this.data);
            });

        },
        async search$(data) {

            this.filter = {...this.filter, ...data};
            await this.list(this.filter);
        },
        async edit(data){
            this.$refs.form.init_data(data);
        },
        async delete$(id) {
            var callback = async () => {
                await userService.delete(id)
                await this.list({...this.filter, page: 1});
            }
            alertService.confirmation(callback, this.$t("facility.global.are-you-sure"), this.$t("facility.global.cannot-reverted"));
        },

    }

}