import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class UserService {

    async list(data) {
        promise = await api.client.get(config.end_point.facility.user.search, {params: data});
        return promise;
    }

    async update(id, data) {
        promise = api.client.put(config.end_point.facility.user.base + '/' + id, data);
        return promise;
    }
}

export const userService = new UserService();
