import FormProfileComponent from "./form.profile.component.vue";
import FormCarComponent from "./form.car.component.vue";
import {alertService} from "@/services/alert/alert.service";

export default {
    components: { FormProfileComponent, FormCarComponent },
    data() {
        return {
            data: {
                booking_user: {},
            },
            config: {
                active: "user",
                edit: null,
            },
        };
    },
    async mounted() {},
    methods: {
        async submit($event) {
            this[$event.method]($event.value);

            if ($event.edited) {
                this.$emit("event", $event.edited);
            }
        },
        async store() {},
        async update() {},
        async init_data(data) {
            let d = { ...data };

            this.data = d;
            this.$bvModal.show("user-form");

            this.init_profile();
        },
        init_profile() {
            alertService.loading();
            this.config.active = "user";
            setTimeout(() => {
                this.$refs["user-profile"].init_data(this.data);
                alertService.close();
            }, 500);
        },
        init_cars() {
            alertService.loading();
            setTimeout(() => {
                this.$refs["user-car"].init_data(this.data.booking_user.car);
                alertService.close();
            }, 500);
        },
        is_editable(data) {
            this.config.edit = data;
        },
    },
};
