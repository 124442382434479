<template>
    <form autocomplete="off"  @submit.prevent="submit()">
        <div class="row">
            <div class="col-lg-12">
                <label class="">{{ $t('facility.user-search.name') }} (Kana)</label>
                <input v-model="data.name" :disabled="!config.edit" class="form-control " required type="text"/>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="">{{ $t('facility.user-search.email') }}</label>
                <input v-model="data.email" class="form-control " disabled required type="text"/>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="">{{ $t('facility.user-search.phone') }}</label>
                <input  required @keypress="numberOnly($event)" @change="numberOnly($event)" v-model="data.phone_number" :disabled="!config.edit" class="form-control" type="text"/>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="">{{ $t('facility.user-search.birthdate') }}</label>
                <div class="row">
                    <div class="d-flex col-12 ">
                        <b-form-select
                            v-model="data.birth_year"
                            :disabled="!config.edit"
                            :options="getYears()"
                            required
                        ></b-form-select>
                        <b-form-select
                            v-model="data.birth_month"
                            :disabled="!config.edit"
                            :options="getMonths()"
                            class="mx-1"
                            required
                            text-fild="text"
                            value-field="value"
                        ></b-form-select>
                        <b-form-select
                            v-model="data.birth_day"
                            :disabled="!config.edit"
                            :options="getDays()"
                            required
                            text-fild="text"
                            value-field="value"
                        ></b-form-select>
                    </div>
                </div>
            </div>
             <div class="col-lg-12 mt-2">
                <label class="">{{ $t('facility.user-search.credit-card-information') }}</label>
                <p>
                    {{
                        data.booking_user.stripe_metadata ?
                        $t('facility.user-search.registered') :
                        $t('facility.user-search.not-registered')
                    }}
                </p>
            </div>
        </div>

        <div class="text-center mt-4">
            <template v-if="config.edit == 0">
                <a class="btn btn-outline-info" href="#"
                   @click="config.edit = 1,
                   $emit('event',{method: 'is_editable',value: 'user'})">
                    {{ $t("facility.global.edit") }}
                </a>
            </template>
            <template v-else>
                <a class="btn btn-danger" href="#"
                   @click="config.edit = 0,
                    $emit('event',{method: 'is_editable',value: null})">
                    {{ $t("facility.global.cancel") }}
                </a>
                <b-button class="mx-1" type="submit" variant="info">
                    {{ $t("facility.global.confirm") }}
                </b-button>
            </template>

        </div>
    </form>
</template>

<script>

import {alertService} from "../../../../services/alert/alert.service";
import {userService} from "../../../../services/facility/user/user.service";

export default {
    data() {
        return {
            data: {
                booking_user: {}
            },
            config: {
                edit: 0
            }
        }
    },
    async mounted() {
    },
    methods: {

        async submit() {
            var callback = async () => {
                await userService.update(this.data.id, this.data).then(result => {
                    alertService.success(this.$t("facility.global.success"), this.$t("facility.global.updated"));
                    this.config.edit = 0;
                    // this.$emit("event", true)
                    this.$emit('event', {method: 'is_editable', value: null, edited: true});

                });

            };
            alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));
        },
        init_data(data) {


            let d = {
                ...data, ...{
                    phone_number: data.booking_user.phone,
                    birth_day: data.booking_user.birth_day,
                    birth_month: data.booking_user.birth_month,
                    birth_year: data.booking_user.birth_year,
                },
                auth_type: "guest"
            }

            this.data = d;
        }
    }

}
</script>

<style scoped>

</style>