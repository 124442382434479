<template>
  <div>
    <div class="row my-2" v-if="config.update">
      <div class="col-lg-12">
        <small class="text-danger">{{ error.update }}</small>
      </div>
    </div>
    <template v-if="config.edit">
      <form autocomplete="off" @submit.prevent="submit()">
        <div class="row">
          <div class="col-lg-12">
            <label>{{ $t("facility.user-search.region") }}</label>
            <input v-model="datum.region" class="form-control" required type="text" />
          </div>
          <div class="col-lg-12 mt-2">
            <label>{{ $t("facility.user-search.sort-number") }}</label>
            <input v-model="datum.sort_number" class="form-control" required type="text" />
          </div>
          <div class="col-lg-12 mt-2">
            <label>{{ $t("facility.user-search.hiragana") }}</label>
            <input v-model="datum.hiragana" class="form-control" required type="text" />
          </div>
          <div class="col-lg-12 mt-2">
            <label>{{ $t("facility.user-search.register-number") }}</label>
            <input v-model="datum.registered_number" class="form-control" required type="text" />
          </div>
        </div>

        <div class="text-center mt-4">
          <a class="btn btn-danger" href="#" @click="(config.edit = 0), $emit('event', { method: 'is_editable', value: null })">
            {{ $t("facility.global.cancel") }}
          </a>
          <b-button class="mx-1" type="submit" variant="info">
            {{ $t("facility.global.confirm") }}
          </b-button>
        </div>
      </form>
    </template>
    <template v-else>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Information</th>
            <th class="text-center" scope="col">Control</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in data">
            <td>{{ `${value.region} ${value.registered_number} ${value.hiragana} ${value.sort_number}` }}</td>
            <td class="text-center">
              <b-button :disabled="value.has_booking" class="mx-1" size="sm" variant="primary" @click="edit(value)">{{ $t("facility.global.edit") }} </b-button>
              <b-button :disabled="value.has_booking" class="mx-1" size="sm" variant="danger" @click="delete$(value.id)">{{ $t("facility.global.delete") }} </b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="config.error" class="text-danger text-center">{{ error.delete }}</div>
    </template>
  </div>
</template>

<script>
import { alertService } from "../../../../services/alert/alert.service";
import { carService } from "../../../../services/facility/car/car.service";

export default {
  data() {
    return {
      data: [],
      datum: {},
      config: {
        edit: false,
        error: false,
      },
      error: {
        update: "Something went wrong, please try again.",
        delete: "",
      },
    };
  },
  async mounted() {},
  methods: {
    submit() {
      var callback = async () => {
        await carService
          .update(this.datum.id, this.datum)
          .then((result) => {
            alertService.success(this.$t("facility.global.success"), this.$t("facility.global.updated"));
            this.config.edit = 0;
            this.$emit("event", { method: "is_editable", value: null, edited: true });
            let key = this.data.findIndex((el) => el.id == this.datum.id);
            this.data[key] = {
              ...this.datum,
            };
          })
          .catch((error) => {
            this.config.error = true;
            setTimeout(() => {
              this.config.error = false;
            }, 5000);
          });
      };
      alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));
    },
    edit(data) {
      this.$emit("event", { method: "is_editable", value: "car" });
      this.datum = {
        ...data,
      };
      this.config.edit = true;
    },
    delete$(id) {
      var callback = async () => {
        await carService
          .delete(id)
          .then(() => {
            let key = this.data.findIndex((el) => el.id == id);
            this.data = [];
            let data = [...this.data];
            delete data[key];

            this.data = [...data];
          })
          .catch((error) => {
            this.config.error = true;
            this.error.delete = error.message;
            setTimeout(() => {
              this.config.error = false;
              this.error.delete = "";
            }, 5000);
          });
      };
      alertService.confirmation(callback, this.$t("facility.global.are-you-sure-delete"), this.$t("facility.global.cannot-reverted"));
    },
    init_data(data) {
      this.data = data;
      console.log(this.data);
    },
  },
};
</script>

<style scoped></style>
